import './Advantages.scss';
import {useTranslation} from "react-i18next";


export const Advantages = () => {

    const {t} = useTranslation()
    return <section className={'advantages-sec'} id='advantages'>
        <div className={'G-container'}>
            <h2>{t('advantages-title')}</h2>
            <div className={'advantages-flex G-flex-wrap'}>
                <div className={'advantages-info'}>
                    <h4>{t('advantages-name')}</h4>
                    <p>{t('advantages-text')}</p>
                </div>
                <div className={'advantages-info'}>
                    <h4>{t('advantages-two-name')}</h4>
                    <p>{t('advantages-two-text')}</p>
                </div>
                <div className={'advantages-info'}>
                    <h4>{t('advantages-three-name')}</h4>
                    <p>{t('advantages-three-text')}</p>
                </div>
                <div className={'advantages-info'}>
                    <h4>{t('advantages-four-name')}</h4>
                    <p>{t('advantages-four-text')}</p>
                </div>
                <div className={'advantages-info'}>
                    <h4>{t('advantages-five-name')}</h4>
                    <p>{t('advantages-five-text')}</p>
                </div>
                <div className={'advantages-info'}>
                    <h4>{t('advantages-six-name')}</h4>
                    <p>{t('advantages-six-text')}</p>
                </div>
            </div>
        </div>
    </section>
}
