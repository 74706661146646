import './About.scss';
import {useTranslation} from "react-i18next";
import bannerVideo from "../../assets/video/armand-video.mp4";


export const About = () => {

    const {t} = useTranslation()
    return <section className={'about-sec'} id={'about'}>
        <div className={'G-container'}>
            <h2>{t('about-title')}</h2>
            <div className={'about-block G-justify-between'}>
                <div className={'about-info'}>
                    <p>{t('about-text')}</p>
                    <p>{t('about-two-text')}</p>
                    <p>{t('about-three-text')}</p>

                </div>
                <div className={'about-video'}>
                    <video autoPlay playsInline loop muted>
                        <source src={bannerVideo} type="video/mp4"/>
                    </video>
                </div>
            </div>
        </div>
    </section>
}
