import './Header.scss'
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import useLanguage from "../../hooks/useLanguage";
import {LanguageEnum} from "../../enums/language.enum";
import {useWindowSize} from "react-use";
import headerLogo from '../../assets/images/logo-white.png';

export const Header = () => {

    const {t} = useTranslation()
    const {changeLanguage, language} = useLanguage()
    const windowSize = useWindowSize()
    const [headerScroll, setHeaderScroll] = useState<boolean>(false)
    const [isOpenMenu, setIsOpenMenu] = useState(false)
    const menuList = [
        {title: t('project-title'), id: 'projects'},
        {title: t('services-title'), id: 'services'},
        {title: t('advantages'), id: 'advantages'},
        {title: t('about-title'), id: 'about'},
    ]
    const handleScroll = () => {
        window.addEventListener('scroll', function () {
            if (window.scrollY > 200) {
                setHeaderScroll(true)
            } else {
                setHeaderScroll(false)
            }
        })
    }

    useEffect(() => {
        handleScroll()
    }, [])


    const handleClick = (elementId: string) => {
        const element = document.getElementById(elementId)
        if (element) {
            let headerOffset = 100;
            let elementPosition = element.getBoundingClientRect().top;
            let offsetPosition = elementPosition + window.pageYOffset - headerOffset;

            window.scrollTo({
                top: offsetPosition,
                behavior: "smooth"
            });
        }
    }

    const handleClickMenu = (title: string) => {
        handleClick(title)
        if (window.innerWidth <= 991) {
            setIsOpenMenu(false)
            document.body.classList.toggle('G-hidden')
        }
    }

    const handleClose = () => {
        setIsOpenMenu(false)
        document.body.classList.remove('G-hidden')
    }


    return <header className={`${headerScroll ? 'header-scroll' : ''}`}>
        <div className="G-container">
            <div className="header-main G-align-center G-justify-between">
                <div className="header-logo" onClick={() => {
                    handleClickMenu('about')
                    document.body.classList.remove('G-hidden')
                }}>
                    <img src={headerLogo} alt="logo"/>
                </div>

                <div className={`header-menu ${isOpenMenu ? 'open-menu' : ''}`}>
                    {windowSize.width && windowSize.width <= 767 ? <ul className=" G-align-center mobile-languages">
                        <li className={`header-lang-item ${language === LanguageEnum[LanguageEnum.english] ? 'active' : ''}`} onClick={() => {
                            changeLanguage(LanguageEnum[LanguageEnum.english])
                            handleClose()
                        }}>EN
                        </li>
                        <li className={`header-lang-item ${language === LanguageEnum[LanguageEnum.russian] ? 'active' : ''}`} onClick={() => {
                            changeLanguage(LanguageEnum[LanguageEnum.russian])
                            handleClose()

                        }}>RU
                        </li>
                        <li className={`header-lang-item ${language === LanguageEnum[LanguageEnum.armenian] ? 'active' : ''}`} onClick={() => {
                            changeLanguage(LanguageEnum[LanguageEnum.armenian])
                            handleClose()

                        }}>HY
                        </li>
                    </ul> : null}
                    <ul className=" G-align-center">
                        {menuList.map((item, index) => {
                            return <li onClick={() => handleClickMenu(item.id)} className='header-menu-item'
                                       key={index}>{t(item.title)}</li>
                        })}
                    </ul>

                </div>

                <div className="header-lang G-align-center">
                    <a className='header-phone G-flex' href={'tel:+374033222222'}>
                    </a>
                    {windowSize.width && windowSize.width > 767 ? <ul className=" G-align-center">
                        <li className={`header-lang-item ${language === LanguageEnum[LanguageEnum.english] ? 'active' : ''}`} onClick={() => {
                            changeLanguage(LanguageEnum[LanguageEnum.english])
                            handleClose()
                        }}>EN
                        </li>
                        <li className={`header-lang-item ${language === LanguageEnum[LanguageEnum.russian] ? 'active' : ''}`} onClick={() => {
                            changeLanguage(LanguageEnum[LanguageEnum.russian])
                            handleClose()

                        }}>RU
                        </li>
                        <li className={`header-lang-item ${language === LanguageEnum[LanguageEnum.armenian] ? 'active' : ''}`} onClick={() => {
                            changeLanguage(LanguageEnum[LanguageEnum.armenian])
                            handleClose()

                        }}>HY
                        </li>
                    </ul> : null}
                    <div className={`toggle-menu ${isOpenMenu ? 'open-burger' : ''}`}
                         onClick={() => {
                             setIsOpenMenu(!isOpenMenu)
                             document.body.classList.toggle('G-hidden')
                         }}>
                        <span/>
                        <span/>
                    </div>
                </div>
                {windowSize.width && windowSize.width <= 991 ?
                    <div onClick={handleClose} className={`header-bg ${isOpenMenu ? 'active-bg' : ''}`}/> : null}
            </div>
        </div>
    </header>
}
