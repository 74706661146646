import './Footer.scss';
import {useTranslation} from "react-i18next";
import footerLogo from '../../assets/images/logo-white.png';
import instagram from '../../assets/images/instagram.svg';
import facebook from '../../assets/images/facebook.svg';

export const Footer = () => {
    const {t} = useTranslation()

    return <footer>
        <div className={'G-container'}>
            <div className={'footer-main G-align-center G-justify-between'}>
                <a href="#" className={'footer-logo'}>
                    <img src={(footerLogo)} alt="lofo"/>
                </a>
                <div className={'footer-contact G-align-center'}>
                    <div className={'header-photo'}>
                        <a href="tel:+374 33 773737">
                            <span>+374 33 773737</span>
                        </a>
                        <a href="mailto:armandgroup.am@gmail.com">
                            <span>armandgroup.am@gmail.com</span>
                        </a>
                    </div>
                    <a href="#" target={'_blank'} className={'icon-soc'}>
                        <img src={(instagram)} alt="insatgram"/>
                    </a>
                    <a href="#" target={'_blank'} className={'icon-soc'}>
                        <img src={(facebook)} alt="facebook"/>
                    </a>
                </div>
            </div>
            <div className={'footer-down G-justify-center'}>
                <p>{t('footer-text')}</p>
            </div>
        </div>
    </footer>
}
