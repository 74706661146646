import './Services.scss';
import {useTranslation} from "react-i18next";


export const Services = () => {

    const {t} = useTranslation()
    return <section className={'services-sec'} id={'services'}>
        <div className={'G-container'}>
            <h2>{t('services-title')}</h2>
            <div className={'services-flex G-justify-between'}>
                <div className={'services-info'}>
                    <h4>{t('services-name')}</h4>
                    <p>{t('services-text')}</p>
                </div>
                <div className={'services-info'}>
                    <h4>{t('services-two-name')}</h4>
                    <p>{t('services-two-text')}</p>
                </div>
            </div>
        </div>
    </section>
}
