import './Project.scss';
import {useTranslation} from "react-i18next";
import projcetPhoto from '../../assets/images/armand-1.jpg'


export const Project = () => {

    const {t} = useTranslation()
    return <section className={'project-section'} id='projects'>
        <div className={'G-container'}>
            <h2>{t('project-title')}</h2>
            <div className={'project-cnt G-flex-wrap'}>
                <div className={'project-box'}>
                    <div className={'project-photo'}>
                        <img src={(projcetPhoto)} alt="project"/>
                    </div>
                    <h4>{t('project-name')}</h4>
                </div>
                <div className={'project-box'}>
                    <div className={'project-photo'}>
                        <img src={(projcetPhoto)} alt="project"/>
                    </div>
                    <h4>{t('project-name')}</h4>
                </div>
                <div className={'project-box'}>
                    <div className={'project-photo'}>
                        <img src={(projcetPhoto)} alt="project"/>
                    </div>
                    <h4>{t('project-name')}</h4>
                </div>
                <div className={'project-box'}>
                    <div className={'project-photo'}>
                        <img src={(projcetPhoto)} alt="project"/>
                    </div>
                    <h4>{t('project-name')}</h4>
                </div>
                <div className={'project-box'}>
                    <div className={'project-photo'}>
                        <img src={(projcetPhoto)} alt="project"/>
                    </div>
                    <h4>{t('project-name')}</h4>
                </div>
                <div className={'project-box'}>
                    <div className={'project-photo'}>
                        <img src={(projcetPhoto)} alt="project"/>
                    </div>
                    <h4>{t('project-name')}</h4>
                </div>
            </div>
        </div>
    </section>
}
