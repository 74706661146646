import './Information.scss';
import infoPicture from '../../assets/images/shin.jpg'

export const Information = () => {

    return <section className={'information-sec'}>
        <div className={'G-container'}>
            <div className={'information-photo'}>
                <img src={(infoPicture)} alt="information"/>
            </div>
        </div>
    </section>
}
