import React from 'react';
import {Footer} from "./components/Footer/Footer";
import {Header} from "./components/Header/Header";
import {Banner} from "./components/Banner/Banner";
import {Project} from "./components/Project/Project";
import {Advantages} from "./components/Advantages/Advantages";
import {About} from "./components/About/About";
import {Services} from "./components/Services/Services";
import {Map} from "./components/Map/Map";
import {Information} from "./components/Information/Information";

function App() {
    return (
        <>
            <Header/>
            <Banner/>
            <Services/>
            <Project/>
            <Advantages/>
            <Information/>

            <About/>
            <Map/>
            <Footer/>
        </>
    );
}

export default App;
