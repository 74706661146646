import './Banner.scss';
import {useTranslation} from "react-i18next";
import bannerVideo from '../../assets/video/armand-video.mp4'

export const Banner = () => {
    const {t} = useTranslation()
    return <section className={'banner-sec'}>
        <div className={'banner-video'}>
            <video autoPlay playsInline loop muted>
                <source src={bannerVideo} type="video/mp4"/>
            </video>
        </div>
        <div className={'banner-main'}>
            <div className={'G-container'}>
                <div className={'banner-info'}>
                    <h1>{t('banner-title')}</h1>
                    <p>{t('banner-text')}</p>
                </div>
            </div>
        </div>
    </section>
}
